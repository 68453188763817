<template>
  <div>
    <header class="page-header">
      <PageTitle/>
      <div class="filters">
        <formulate-input placeholder="Search" @input="searchUsers" type="text" v-model="search"></formulate-input>
      </div>
      <div class="buttons">
        <formulate-input v-model="withoutDealers" :label="$t('Gebruikers zonder dealer')" type="checkbox" @change="searchUsers()"></formulate-input>
        <button class="btn btn-download" @click="exportOverview">Exporteer overzicht</button>
        <router-link :to="{ name: 'admin.user.add' }" class="btn btn-add" v-if="!isLoading">
          <span>{{ 'Gebruiker toevoegen' }}</span>
        </router-link>
      </div>
    </header>

    <div class="main-content">
      <div class="pane table-pane" :class="{'loading': isLoading}">
        <div class="table-wrapper">
          <div class="table-element">
            <table class="users" v-if="!isLoading">
              <thead>
              <tr>
                <th :class="(sortBy === 'id' ? 'active ' + sortDir : '')" @click="sortUsers('id')" class="id">
                  <div>ID</div>
                </th>
                <th :class="(sortBy === 'email' ? 'active ' + sortDir : '')" @click="sortUsers('email')">
                  <div>E-mail</div>
                </th>
                <th :class="(sortBy === 'last_login' ? 'active ' + sortDir : '')" @click="sortUsers('last_login')">
                  <div>Laatste login</div>
                </th>
                <th :class="(sortBy === 'is_superuser' ? 'active ' + sortDir : '')" @click="sortUsers('is_superuser')">
                  <div>Superuser</div>
                </th>
                <th :class="(sortBy === 'created' ? 'active ' + sortDir : '')" @click="sortUsers('created')">
                  <div>Aangemaakt</div>
                </th>
              </tr>
              </thead>

              <tbody v-if="users.length > 0">
              <tr @click="$router.push({name: 'admin.user', params: {id: user.id}})" :key="user.id" @contextmenu.prevent.stop="userRightClick($event, user)" v-for="user in users">
                <td class="id">{{ user.id }}</td>
                <td>
                  <div class="status-icon" :class="{'enabled': !user.disabled, 'disabled': user.disabled}"></div>
                  {{ user.email }}
                </td>
                <td>
                  {{ user.last_login ? user.last_login.setLocale('nl').toFormat('dd/LL/yyyy') : '&nbsp;' }}
                </td>
                <td>
                  <img width="15" v-if="user.is_superuser" src="@/assets/img/check.svg" alt="check">&nbsp;
                </td>
                <td>{{ user.created.setLocale('nl').toFormat('dd/LL/yyyy') }}
                </td>
              </tr>
              </tbody>
            </table>
          </div>

          <div class="pagination">
            <div class="results">
              {{ total.min }} - {{ total.max }} van {{ $store.state.UsersModule.usersTotal }} gebruikers
            </div>

            <div class="buttons">
              <button class="btn-inverse" :disabled="page === 0" @click="prevPage()">Prev</button>
              <button class="btn-inverse" :disabled="users.length !== perPage" @click="nextPage()">Next</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <vue-simple-context-menu element-id="vueSimpleContextMenu" :options="contextMenuOptions" ref="vueSimpleContextMenu" @option-clicked="contextOptionClicked"></vue-simple-context-menu>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import { notification } from '@/notifications'
import { ApiService } from '@/services/admin/api.service'
import PageTitle from '@/components/admin/PageTitle'

export default {
  components: { PageTitle },
  data: function () {
    return {
      isLoading: true,
      withoutDealers: false,
      search: '',
      contextMenuOptions: [
        { id: 'deleteUser', name: 'Gebruiker verwijderen' }
      ]
    }
  },
  async mounted () {
    this.isLoading = true
    await this.$store.dispatch('UsersModule/fetchUsers')
    this.isLoading = false
  },
  methods: {
    async exportOverview () {
      await ApiService.exportAllUsers(this.withoutDealers).then(res => {
        const url = window.URL.createObjectURL(new Blob([res.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'gebruikers.csv')
        document.body.appendChild(link)
        link.click()
      })
    },
    async sortUsers (sortBy) {
      let sortDir = 'asc'

      if (sortBy === this.sortBy) {
        sortDir = this.sortDir === 'asc' ? 'desc' : 'asc'
      }

      console.log(this.withoutDealers)

      await this.$store.dispatch('UsersModule/fetchUsers', {
        sortBy: sortBy,
        sortDir: sortDir,
        withoutDealers: this.withoutDealers,
        search: this.search
      })
    },
    async searchUsers () {
      await this.$store.dispatch('UsersModule/fetchUsers', {
        page: 0,
        search: this.search,
        withoutDealers: this.withoutDealers
      })
    },
    userRightClick: async function (event, user) {
      if (this.checkPermissions(['user.delete'])) {
        this.$refs.vueSimpleContextMenu.showMenu(event, user)
      }
    },
    contextOptionClicked: async function (event) {
      await this.deleteUser(event.item.id)
    },
    deleteUser: async function (userId) {
      await ApiService.deleteUser(userId)
        .then(res => {
          if (res.status === 200) {
            notification('Gebruiker succesvol verwijderd!')
            this.$store.dispatch('UsersModule/fetchUsers')
          }
        })
    },
    prevPage: async function () {
      if (this.page > 0) {
        await this.$store.dispatch('UsersModule/fetchUsers', {
          page: this.page - 1,
          perPage: this.perPage,
          search: this.search,
          withoutDealers: this.withoutDealers
        })
      }
    },
    nextPage: async function () {
      if (this.users.length === this.perPage) {
        await this.$store.dispatch('UsersModule/fetchUsers', {
          page: this.page + 1,
          perPage: this.perPage,
          search: this.search,
          withoutDealers: this.withoutDealers
        })
      }
    }
  },
  computed: {
    page: function () {
      return this.$store.state.UsersModule.page
    },
    perPage: function () {
      return this.$store.state.UsersModule.perPage
    },
    sortBy: function () {
      return this.$store.state.UsersModule.sortBy
    },
    sortDir: function () {
      return this.$store.state.UsersModule.sortDir
    },
    users: function () {
      const users = []
      const rawUsers = this.$store.getters['UsersModule/getUsers']

      if (rawUsers) {
        rawUsers.forEach((userRow, i) => {
          const user = {}

          for (const prop in userRow) {
            if ((prop === 'created' || prop === 'last_login') && userRow[prop] != null) {
              user[prop] = DateTime.fromISO(userRow[prop])
            } else {
              user[prop] = userRow[prop]
            }
          }

          users.push(user)
        })
      }

      return users
    },
    total: function () {
      let offsetMax = this.perPage * (this.page + 1)
      const offsetMin = offsetMax - this.perPage + 1

      if (offsetMax > this.$store.state.UsersModule.usersTotal) {
        offsetMax = this.$store.state.UsersModule.usersTotal
      }

      return {
        min: offsetMin,
        max: offsetMax
      }
    },
    formatRoles: function () {
      const roles = {}

      this.roles.forEach((roleRow, i) => {
        roles[roleRow.id] = roleRow.name
      })

      return roles
    }
  }
}
</script>
